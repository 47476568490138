















































import html2pdf from 'html2pdf.js/src'
import { ZOOM_LEVEL_DAY } from '@/components/bryntum/configs/ZoomLevelsConfig'
import CalendarExportPage from '@/components/export/CalendarExportPage.vue'
import SchedulerToolbarExport from '@/components/SchedulerToolbarExport.vue'
import { DateHelper, TimeSpan } from '@bryntum/schedulerpro'
import {
  SchedulerExportConfig,
  PAGE_ORIENTATION_LANDSCAPE,
  PAGE_ORIENTATION_PORTRAIT,
} from '@/components/bryntum/configs/SchedulerExportConfig'

const CANVAS_SCALE_X4 = 4
const CANVAS_SCALE_X2 = 2

const MIN_FONT_SCALE = 10
const MAX_FONT_SCALE = 100
const DEFAULT_FONT_SCALE = 100

const MIN_WORKING_TIME = 0
const MAX_WORKING_TIME = 24
const DEFAULT_WORKING_TIME_RANGE = [13, 21]

export default {
  name: 'CalendarExport',

  components: {
    SchedulerToolbarExport,
    CalendarExportPage,
  },

  props: {
    startDate: { type: Date, required: true },
    endDate: { type: Date, required: true },
    project: { type: Object, required: true },
    squad: { type: Object, required: true },
    originalTickSize: { type: Number, required: true },
  },

  emits: [
    'pdfExportEnd',
  ],

  data: () => ({
    SchedulerExportConfig,
    ZOOM_LEVEL_DAY,
    // A4 paper size 8.27 x 11.69 inches which is 1:1.4153
    // Commonly used A-type paper aspect ratio is 1.4142
    // 740 x 1046 pixels gives 7.7 x 10.9 inches and 100% scale while printing in Fit mode
    pageFormat: 'a4',
    a4PaperLongSidePx: 1046,
    a4PaperShortSidePx: 740,
    pageOrientation: PAGE_ORIENTATION_PORTRAIT,
    scale: DEFAULT_FONT_SCALE,
    minScale: MIN_FONT_SCALE,
    maxScale: MAX_FONT_SCALE,
    workingTimeRange: DEFAULT_WORKING_TIME_RANGE,
    minWorkingTime: MIN_WORKING_TIME,
    maxWorkingTime: MAX_WORKING_TIME,
    filterNonWorkingTime: false,
    filterLocations: false,
    fileName: '',
    comment: '',
    tickCount: 0,
    tickSize: 0,
    isSaving: false,
  }),

  computed: {
    pageHeight(): number {
      return this.pageOrientation === PAGE_ORIENTATION_LANDSCAPE ? this.a4PaperShortSidePx : this.a4PaperLongSidePx
    },

    pageWidth(): number {
      return this.pageOrientation === PAGE_ORIENTATION_LANDSCAPE ? this.a4PaperLongSidePx : this.a4PaperShortSidePx
    },

    fontSize(): number {
      return this.scale / 100
    },

    pageStyle(): Record<string, string> {
      return {
        'min-height': `${this.pageHeight}px`,
        'height': `${this.pageHeight}px`,
        'max-height': `${this.pageHeight}px`,
        'min-width': `${this.pageWidth}px`,
        'width': `${this.pageWidth}px`,
        'max-width': `${this.pageWidth}px`,
      }
    },

    schedulerStyle(): Record<string, string> {
      return {
        'font-size': `${this.fontSize}em`,
      }
    },

    suggestedFileName(): string {
      return `schedule-${DateHelper.format(this.startDate, 'YYYY-MM-DD')}`
    },

    // Decrease scale factor for more than 7 days due to HTML5 canvas size limitation
    // https://github.com/eKoopmans/html2pdf.js/issues/19#issuecomment-315583260
    canvasScale(): number {
      return this.dates.length <= 7 ? CANVAS_SCALE_X4 : CANVAS_SCALE_X2
    },

    dates(): TimeSpan[] {
      const dates: TimeSpan[] = []
      let currentStartDate: Date = DateHelper.clone(this.startDate)

      while (currentStartDate < this.endDate) {
        let currentEndDate = DateHelper.getStartOfNextDay(currentStartDate, true)

        const timeSpan = new TimeSpan({
          startDate: currentStartDate,
          endDate: currentEndDate,
        })

        dates.push(timeSpan)

        currentStartDate = DateHelper.clone(currentEndDate)
      }

      return dates
    },
  },

  mounted(): void {
    console.log('mounted')
    this.updateFileName(this.suggestedFileName)
  },

  destroyed(): void {
    console.log('destroyed')
  },

  methods: {
    updateFileName(value: string): void {
      this.fileName = value
    },

    updateScale(value: number): void {
      requestAnimationFrame(() => {
        this.scale = value
      })
    },

    updateWorkingTimeRange(value: number[]): void {
      this.workingTimeRange = value
    },

    updatePageOrientation(value: string): void {
      this.pageOrientation = value
    },

    updateComment(value: string): void {
      this.comment = value
    },

    async printFile(): Promise<void> {
      this.isSaving = true

      // https://ekoopmans.github.io/html2pdf.js/#options
      const exportOptions = {
        filename: `${this.fileName}.pdf`,
        image: { type: 'jpeg', quality: 1 },
        // https://html2canvas.hertzen.com/configuration
        html2canvas:  {
          // Defaults to `window.devicePixelRatio` which is 2 on a retina display.
          // Set it higher for better image quality, especially important for computers with regular HD displays.
          // As a downside, it increases the file size too. For example, it goes from 750KB to 2MB for one page.
          scale: this.canvasScale,
          // Defaults to 15000 ms, set to 0 to disable the timeout.
          imageTimeout: 0,
        },
        // https://rawgit.com/MrRio/jsPDF/master/docs/jsPDF.html
        jsPDF: {
          orientation: this.pageOrientation,
          format: [this.pageWidth, this.pageHeight],
          unit: 'px',
          hotfixes: ['px_scaling'],
          putOnlyUsedFonts: true,
        },
      }

      console.time('export time')
      // https://ekoopmans.github.io/html2pdf.js
      await html2pdf(this.$refs.exportPages, exportOptions)
      console.timeEnd('export time')

      this.isSaving = false

      this.exit()
    },

    exit(): void {
      this.$emit('pdfExportEnd')
    },

    // filterOutEmptyLocations () {
    //   this.scheduler.resourceStore.filterBy(location => {
    //     return this.scheduler.eventStore.getEvents({
    //       resourceRecord: location,
    //       startDate: this.scheduler.timeAxis.startDate,
    //       endDate: this.scheduler.timeAxis.endDate
    //     }).length > 0
    //   })
    // },

    // resetLocationFilters () {
    //   this.scheduler.resourceStore.clearFilters()
    // },
  }
}
