



































































import { BryntumButton, BryntumDateField, BryntumWidget } from '@bryntum/schedulerpro-vue'
// import { DurationConfig } from '@bryntum/schedulerpro'
import {
  StatusFilterConfig,
  FitButtonConfig,
  SizingButtonConfig,
  ThemeButtonConfig,
  ViewPresetButtonConfig,
  FIT_MENU_ITEM_FILL,
  FIT_MENU_ITEM_FIT,
} from '@/components/bryntum/configs/ToolbarConfigs'
import { ZOOM_LEVELS } from '@/components/bryntum/configs/ZoomLevelsConfig'
import { SchedulerConfig } from '@/components/bryntum/configs/SchedulerConfig'

interface DurationConfig {
  magnitude: number
  unit: string
}

export default {
  name: 'SchedulerToolbar',

  components: {
    BryntumButton,
    BryntumDateField,
    BryntumWidget,
  },

  props: {
    startDate: { type: Date, default: undefined },
    viewPreset: { type: [String, Object], default: undefined },
    themeId: { type: String, default: undefined },
    fitColumns: { type: String, default: undefined },
    columnWidth: { type: Number, default: undefined },
    tickHeight: { type: Number, default: undefined },
    readOnlyView: { type: Boolean, default: undefined },
    filters: {
      type: Object,
      default: (): { status?: string } => ({
        status: undefined,
      })
    },
  },

  emits: [
    'update:filterStatus',
    'update:startDate',
    'update:viewPreset',
    'update:themeId',
    'update:fillWidth',
    'update:fitWidth',
    'update:columnWidth',
    'update:tickHeight',
    'pdfExportStart',
  ],

  data() {
    return {
      buttonMinWidth: 42,
      SchedulerConfig,
      fitButtonInstance: null,
      sizingButtonInstance: null,
      themeButtonInstance: null,
      viewPresetButtonInstance: null,
      statusFilterInstance: null,
    }
  },

  computed: {
    mdAndDown() {
      return this.$vuetify.breakpoint.mdAndDown
    },

    viewPresetId() {
      if (typeof this.viewPreset === 'string') {
        return this.viewPreset
      }
      if (typeof this.viewPreset === 'object') {
        return this.viewPreset.id
      }
      return undefined
    },

    datePickerStep() {
      let magnitude = 1
      let unit = 'day'

      if (this.viewPresetId && ZOOM_LEVELS[this.viewPresetId]) {
        magnitude = ZOOM_LEVELS[this.viewPresetId].shiftIncrement as number
        unit = ZOOM_LEVELS[this.viewPresetId].shiftUnit as string
      }

      const step: DurationConfig = {
        magnitude,
        unit,
      }

      return step
    },

    selectedFitMenuItem() {
      return this.fitButtonInstance?.menu.items.find((item: { id: string }) => item.id === this.fitColumns) || {}
    },

    fitButtonCfg() {
      const { __text, __icon, __tooltip } = this.selectedFitMenuItem

      const result = {
        ...FitButtonConfig,
        ...(__text && { text: __text }),
        ...(__icon && { icon: __icon }),
        ...(__tooltip && { tooltip: __tooltip }),
      }

      // Show text only for large screens
      if (this.mdAndDown) {
        result.text = ''
      }

      return result
    },

    sizingButtonCfg() {
      const result = { ...SizingButtonConfig }

      // Show text only for large screens
      if (this.mdAndDown) {
        result.text = ''
      }

      return result
    },

    selectedThemeMenuItem() {
      return this.themeButtonInstance?.menu.items.find((item: { id: string }) => item.id === this.themeId) || {}
    },

    themeButtonCfg() {
      const { __text, __icon } = this.selectedThemeMenuItem

      const result = {
        ...ThemeButtonConfig,
        ...(__text && { text: __text }),
        ...(__icon && { icon: __icon }),
      }

      // Show text only for large screens
      if (this.mdAndDown) {
        result.text = ''
      }

      return result
    },

    selectedViewPresetMenuItem() {
      return this.viewPresetButtonInstance?.menu.items.find((item: { id: string }) => item.id === this.viewPresetId) || {}
    },

    viewPresetButtonCfg() {
      const { name } = this.selectedViewPresetMenuItem

      return  {
        ...ViewPresetButtonConfig,
        ...(name && { text: name }),
      }
    },

    selectedStatusFilterMenuItem() {
      return this.statusFilterInstance?.menu.items.find((item: { id: string }) => item.id === this.filters.status) || {}
    },

    statusFilterCfg() {
      const { text, __tooltip } = this.selectedStatusFilterMenuItem

      return  {
        ...StatusFilterConfig,
        ...(text && { text }),
        ...(__tooltip && { tooltip: __tooltip }),
      }
    },
  },

  watch: {
    fitColumns: function (value: string): void {
      this.setFitItems(value)
    },
    columnWidth: function (value: string): void {
      this.setColumnWidth(value)
    },
    tickHeight: function (value: string): void {
      this.setTickHeight(value)
    },
    themeId: function (value: string): void {
      this.setTheme(value)
    },
    viewPreset: function (): void {
      this.setViewPreset(this.viewPresetId)
    },
    'filters.status': function (value: string): void {
      this.setStatusFilter(value)
    }
  },

  mounted(): void {
    this.initViewPresetButton()
    this.initFitButton()
    this.initSizingButton()
    this.initThemeButton()
    this.initFilters()
  },

  beforeDestroy(): void {
    const { columnWidth, tickHeight } = this.sizingButtonInstance.menu.widgetMap

    this.fitButtonInstance.menu.un('toggle')
    this.fitButtonInstance = null

    columnWidth.un('input')
    tickHeight.un('input')
    this.sizingButtonInstance = null

    this.themeButtonInstance.menu.un('toggle')
    this.themeButtonInstance = null

    this.viewPresetButtonInstance.menu.un('toggle')
    this.viewPresetButtonInstance = null

    this.statusFilterInstance.menu.un('toggle')
    this.statusFilterInstance = null
  },

  methods: {
    // Selects the item and deselects the others
    selectMenuItem(items: { id: string, checked: boolean }[], itemId: string): void {
      items.forEach((item) => {
        const itemToSelect = item.id === itemId

        if (item.checked !== itemToSelect) {
          item.checked = itemToSelect
        }
      })
    },

    //region FitButton
    initFitButton(): void {
      this.fitButtonInstance = this.$refs.fitButton.instance
      this.setFitItems(this.fitColumns)
      this.fitButtonInstance.menu.on('toggle', this.onFitUpdate)
    },

    setFitItems(id: string): void {
      this.selectMenuItem(this.fitButtonInstance.menu.items, id)
    },

    onFitUpdate({ item, checked }: any): void {
      // React to "check" and ignore "uncheck" change
      if (checked) {
        // Need to fire update events for both since there is a complex logic based on these 2 properties
        this.$emit('update:fillWidth', item.id === FIT_MENU_ITEM_FILL)
        this.$emit('update:fitWidth', item.id === FIT_MENU_ITEM_FIT)
      }
    },
    //endregion

    //region SizingButton
    initSizingButton(): void {
      this.sizingButtonInstance = this.$refs.sizingButton.instance
      const { columnWidth, tickHeight } = this.sizingButtonInstance.menu.widgetMap

      this.setColumnWidth(this.columnWidth)
      this.setTickHeight(this.tickHeight)

      columnWidth.on('input', this.onColumnWidthUpdate)
      tickHeight.on('input', this.onTickHeightUpdate)
    },

    setColumnWidth(value: number): void {
      const { columnWidth } = this.sizingButtonInstance.menu.widgetMap
      columnWidth.value = value
    },

    onColumnWidthUpdate({ value }: { value: number }): void {
      this.$emit('update:columnWidth', value)
    },

    setTickHeight(value: number): void {
      const { tickHeight } = this.sizingButtonInstance.menu.widgetMap
      tickHeight.value = value
    },

    onTickHeightUpdate({ value }: { value: number }): void {
      this.$emit('update:tickHeight', value)
    },
    //endregion

    //region ThemeButton
    initThemeButton(): void {
      this.themeButtonInstance = this.$refs.themeButton.instance
      this.setTheme(this.themeId)
      this.themeButtonInstance.menu.on('toggle', this.onThemeUpdate)
    },

    setTheme(id: string): void {
      this.selectMenuItem(this.themeButtonInstance.menu.items, id)
    },

    onThemeUpdate({ item, checked }: any): void {
      // React to "check" and ignore "uncheck" change
      if (checked) {
        this.$emit('update:themeId', item.id)
      }
    },
    //endregion

    //region ViewPresetButton
    initViewPresetButton(): void {
      this.viewPresetButtonInstance = this.$refs.viewPresetButton.instance
      this.setViewPreset(this.viewPresetId)
      this.viewPresetButtonInstance.menu.on('toggle', this.onViewPresetUpdate)
    },

    setViewPreset(id: string): void {
      this.selectMenuItem(this.viewPresetButtonInstance.menu.items, id)
    },

    onViewPresetUpdate({ item, checked }: any): void {
      // React to "check" and ignore "uncheck" change
      if (checked) {
        this.$emit('update:viewPreset', item.id)
      }
    },
    //endregion

    //region Filters
    initFilters(): void {
      this.initStatusFilter()
    },

    initStatusFilter(): void {
      this.statusFilterInstance = this.$refs.statusFilter.instance
      this.setStatusFilter(this.filters.status)
      this.statusFilterInstance.menu.on('toggle', this.onStatusFilterUpdate)
    },

    setStatusFilter(id: string): void {
      this.selectMenuItem(this.statusFilterInstance.menu.items, id)
    },

    onStatusFilterUpdate({ item, checked }: any): void {
      // React to "check" and ignore "uncheck" change
      if (checked) {
        this.$emit('update:filterStatus', { value: item.id })
      }
    },
    //endregion

    onDateSelection({ value, userAction }: { value: Date, userAction: boolean }): void {
      if (userAction) {
        this.$emit('update:startDate', value)
      }
    },
  }
}
