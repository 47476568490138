










import { BryntumGrid } from '@bryntum/schedulerpro-vue'
import { PlayerGridConfig } from '@/components/bryntum/configs/PlayerGridConfig'

export default {
  name: 'PlayerGrid',

  components: {
    BryntumGrid,
  },

  props: {
    rawData: { type: Array, default: undefined },
    isAdmin: { type: Boolean, default: false },
  },

  emits: [
    'gridMounted',
    'gridUnmounted',
  ],

  data() {
    return {
      PlayerGridConfig,
      gridInstance: null,
    }
  },

  computed: {
    createPlayerUrl(): string {
      return `${process.env.VUE_APP_ADMIN_PORTAL_URL}/players/create`
    },
  },

  watch: {
    isAdmin(value: boolean): void {
      this.updateGridRights(value)
    }
  },

  mounted(): void {
    this.gridInstance = this.$refs.playerGrid.instance

    this.updateGridRights(this.isAdmin)
    this.gridInstance.on('addNewPlayerClick', this.onAddNewPlayerClick)

    this.$emit('gridMounted', this.gridInstance)
  },

  beforeDestroy(): void {
    this.gridInstance.un('addNewPlayerClick')

    this.$emit('gridUnmounted', this.gridInstance)
  },

  methods: {
    updateGridRights(isAdmin: boolean): void {
      this.gridInstance.adminRights = isAdmin
    },
    onAddNewPlayerClick(): void {
      if (this.isAdmin) {
        window.open(this.createPlayerUrl)
      }
    },
  }
}
