import { ComboConfig, DomClassList } from '@bryntum/schedulerpro'
import PlayerStore from '@/components/bryntum/stores/PlayerStore'
import PlayerModel from '@/components/bryntum/models/PlayerModel'

const PlayersComboConfig: Partial<ComboConfig> = {
  displayField: 'title',
  valueField: null, // "null" means return record as a value
  multiSelect: true,
  clearable: true,
  // TODO: declare store type once the issue is fixed: https://github.com/bryntum/support/issues/5021
  store: {
    ...PlayerStore.defaultConfig,
  },
  chipView: {
    iconTpl: (record: PlayerModel): string => {
      const classList = new DomClassList({
        'b-fa': true,
        'b-fa-user': record.isAthlete,
        'b-fa-user-tie': record.isStaff,
        'player-icon': true,
        'player-injured': !record.isAvailable,
      })
      const tooltip = `${record.positionLabel} / ${record.roleText} / ${record.availabilityText}`
      return `<i data-btip="${tooltip}" class="${classList.value}"></i>`
    },
    itemTpl: (record: PlayerModel) => `<span>${record.fullNameAbbr}</span>`,
  },
  listItemTpl: (record: PlayerModel): string => {
    const classList = new DomClassList({
      'b-fa': true,
      'b-fa-user': record.isAthlete,
      'b-fa-user-tie': record.isStaff,
      'player-icon': true,
      'player-injured': !record.isAvailable,
    })
    const tooltip = `${record.roleText} / ${record.availabilityText}`
    return `
      <div>
        <i data-btip="${tooltip}" class="${classList.value}"></i>
        <span>${record.fullName}</span>
      </div>
    `
  },
  picker: {
    cls: 'playersPicker',
    allowGroupSelect: false,
  },
}

export { PlayersComboConfig }
