export const CONFIRM_UPDATE_PUBLISHED_SESSION = {
  title: 'Confirm update',
  message: 'Do you want to update the published session?',
  okButton: 'Yes',
  cancelButton: 'No'
}

export const CONFIRM_RESET_PUBLISHED_SESSION = {
  title: 'Confirm changes',
  message: 'The session will be rolled back to the state when it was published and the tracked data will be lost. Do you want to rollback the session?',
  okButton: 'Yes',
  cancelButton: 'No'
}

export const CONFIRM_UNPUBLISH_PUBLISHED_SESSION = {
  title: 'Confirm changes',
  message: 'The session will stay intact but the tracked data will be lost. Do you want to unpublish the session?',
  okButton: 'Yes',
  cancelButton: 'No'
}

export const CONFIRM_DELETE_PUBLISHED_SESSION = {
  title: 'Confirm delete',
  message: 'Do you want to delete the published session?',
  okButton: 'Yes',
  cancelButton: 'No'
}

export const CONFIRM_DELETE_SESSION = {
  title: 'Confirm delete',
  message: 'Do you want to delete the session?',
  okButton: 'Yes',
  cancelButton: 'No'
}

export const CONFIRM_REMOVE_PLAYER_FROM_SESSION = {
  title: 'Confirm athlete removal',
  message: 'Do you want to remove the athlete from the session? All workload data will be lost.',
  okButton: 'Yes',
  cancelButton: 'No'
}
