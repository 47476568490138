import { DataFieldConfig, DateHelper, EventModel, Model, ObjectHelper, StringHelper } from '@bryntum/schedulerpro'
import SessionElementModel from '@/components/bryntum/models/SessionElementModel'
import WorkloadModel from '@/components/bryntum/models/WorkloadModel'
import PlayerModel from '@/components/bryntum/models/PlayerModel'
import GroupModel from '@/components/bryntum/models/GroupModel'
import SessionTemplateModel from '@/components/bryntum/models/SessionTemplateModel'
import EventTypeModel from '@/components/bryntum/models/EventTypeModel'
import BenchmarkDateHelper from '@/components/bryntum/helper/BenchmarkDateHelper'

const SESSION_STATUS_PUBLISHED = 'published'
const SESSION_STATUS_DRAFT = 'draft'

interface SessionModel {
  rpe: number | null
  _workload: number | null
  surfaceId: number
  status: 'published' | 'draft'
  publicNote: string
  players: PlayerModel[]
  sessionElements: SessionElementModel[]
  playerWorkloads: WorkloadModel[]
  groups: GroupModel[]
  sessionTemplateId: number
  sessionTemplate: SessionTemplateModel
  sessionType: EventTypeModel
  isSnapshot: boolean
  snapshotId: number | null
}

// Explicitly extends Bryntum types and adds missing types
// TODO: remove once the issue is fixed: https://github.com/bryntum/support/issues/5017
interface DataFieldConfigExtension extends DataFieldConfig {
  type: string

  // TODO: remove once the issue is fixed: https://www.bryntum.com/forum/viewtopic.php?f=51&t=22096
  convert(value: unknown): unknown

  serialize(value: unknown, record: Model): unknown
}

export interface GroupInfo {
  group: GroupModel
  removedPlayers: PlayerModel[]
  isGroupInfo: boolean
}

interface GroupInfoMap {
  [groupId: string]: GroupInfo
}

interface PlayerModelMap {
  [playerId: string]: PlayerModel
}

/**
 * SessionModel is used to describe session in events[]
 */
class SessionModel extends EventModel {
  static $name = 'SessionModel'

  get isSessionModel(): boolean {
    return true
  }

  static get fields(): Partial<DataFieldConfigExtension>[] {
    return [
      { name: 'rpe', type: 'number', defaultValue: null },
      { name: 'workload', type: 'number', defaultValue: null },
      { name: 'surfaceId', type: 'number', defaultValue: null },
      { name: 'duration', type: 'number', defaultValue: 60 },
      { name: 'durationUnit', type: 'durationunit', defaultValue: 'minute' },
      { name: 'status', type: 'string', defaultValue: 'draft' },
      { name: 'publicNote', type: 'string', defaultValue: '' },
      { name: 'isSnapshot', type: 'boolean', defaultValue: false },
      { name: 'snapshotId', type: 'number', defaultValue: null },
      {
        name: 'players',
        type: 'array',
        defaultValue: [],
        convert(players: any[]) {
          return players.map((player): PlayerModel => {
            return player instanceof PlayerModel ? player : new PlayerModel(player)
          })
        }
      },
      {
        name: 'sessionElements',
        type: 'array',
        defaultValue: [],
        convert(elements: any[]) {
          return elements.map((element): SessionElementModel => {
            return element instanceof SessionElementModel ? element : new SessionElementModel(element)
          })
        }
      },
      {
        name: 'playerWorkloads',
        type: 'array',
        defaultValue: [],
        convert(workloads: any[]) {
          return workloads.map((workload): WorkloadModel => {
            return workload instanceof WorkloadModel ? workload : new WorkloadModel(workload)
          })
        }
      },
      {
        name: 'groups',
        type: 'array',
        defaultValue: [],
        convert(groups: any[]) {
          return groups.map((group) => {
            return group instanceof GroupModel ? group : new GroupModel(group)
          })
        }
      },
      { name: 'sessionTemplateId', type: 'number' },
      { name: 'sessionTemplate', type: 'object' },
      { name: 'sessionType', type: 'object' },
    ]
  }

  get niceDuration(): string {
    return BenchmarkDateHelper.convertDurationToNiceTime(this.duration, this.durationUnit)
  }

  set workload(value: number | null) {
    this._workload = this.calculateWorkload
  }

  get workload(): number | null {
    return this.calculateWorkload
  }

  get calculateWorkload(): number | null {
    return this.rpe != null && this.duration != null ? this.rpe * this.duration : null
  }

  get published(): boolean {
    return this.status === SESSION_STATUS_PUBLISHED
  }

  set published(value: boolean) {
    this.status = value ? SESSION_STATUS_PUBLISHED : SESSION_STATUS_DRAFT
  }

  get draft(): boolean {
    return this.status === SESSION_STATUS_DRAFT
  }

  set draft(value: boolean) {
    this.status = value ? SESSION_STATUS_DRAFT : SESSION_STATUS_PUBLISHED
  }

  get playerNames(): string[] {
    return this.players.map(rec => rec.fullName)
  }

  /**
   * Returns players that do not exist in any of the session groups
   */
  get standalonePlayers(): PlayerModel[] {
    let players = [...this.players]

    if (players.length > 0) {
      this.groups.forEach(group => {
        const groupPlayerIds = group.players.map(player => player.id)
        players = players.filter(player => !groupPlayerIds.includes(player.id))
      })
    }

    return players
  }

  /**
   * Returns athletes that do not exist in any of the session groups
   */
  get standaloneAthletes(): PlayerModel[] {
    return this.standalonePlayers.filter(rec => rec.isAthlete)
  }

  /**
   * Returns a map of the athletes that do not exist in any of the session groups
   */
  get standaloneAthletesMap(): PlayerModelMap {
    return this.standaloneAthletes.reduce((map, athlete) => {
      return {
        ...map,
        [athlete.id]: athlete
      }
    }, {})
  }

  /**
   * Returns staff that do not exist in any of the session groups
   */
  get standaloneStaff(): PlayerModel[] {
    return this.standalonePlayers.filter(rec => rec.isStaff)
  }

  /**
   * Returns a map of the staff that do not exist in any of the session groups
   */
  get standaloneStaffMap(): PlayerModelMap {
    return this.standaloneStaff.reduce((map, staff) => {
      return {
        ...map,
        [staff.id]: staff
      }
    }, {})
  }

  get athleteNames(): string[] {
    return this.players.filter(rec => rec.isAthlete).map(rec => {
      return StringHelper.encodeHtml(rec.fullName)
    })
  }

  get staffNames(): string[] {
    return this.players.filter(rec => rec.isStaff).map(rec => {
      return StringHelper.encodeHtml(rec.fullName)
    })
  }

  /**
   * Returns data about players that have been removed from the session but exist in the group
   */
  get groupsWithPlayerDiff(): GroupInfoMap {
    return this.groups.reduce((groupMap, group) => {
      return {
        ...groupMap,
        [group.id]: {
          group,
          removedPlayers: group.players.filter(player => !this.isPlayerAssigned(player.id)),
          isGroupInfo: true
        }
      }
    }, {})
  }

  get formattedStartDate(): string {
    return DateHelper.format(this.startDate as Date, 'L')
  }

  get startTime(): string {
    return DateHelper.format(this.startDate as Date, 'H:mm')
  }

  get endTime(): string {
    return DateHelper.format(this.endDate as Date, 'H:mm')
  }

  public isPlayerAssigned(playerId: string | number): boolean {
    return !!this.players.find((player: PlayerModel) => player.id === playerId)
  }

  public isGroupAssigned(groupId: string | number): boolean {
    return !!this.groups.find((group: GroupModel) => group.id === groupId)
  }

  public assignPlayers(players: PlayerModel[], replace = false): void {
    const existingPlayers = this.players
    const removedAthleteIds = replace ? existingPlayers.filter(rec => rec.isAthlete && !players.includes(rec))
      .map(rec => rec.id) : [] as number[]
    const addedAthleteIds = players.filter(rec => rec.isAthlete && !existingPlayers.includes(rec))
      .map(rec => rec.id) as number[]

    if (replace) {
      this.players = [...players]
    } else {
      this.players = [
        ...existingPlayers,
        // ignore already assigned
        ...players.filter((player: PlayerModel) => !this.isPlayerAssigned(player.id))
      ]
    }

    if (removedAthleteIds.length > 0) {
      this.deletePlayerWorkloads(removedAthleteIds)
      this.removePlayersFromSessionElements(removedAthleteIds)
    }

    if (addedAthleteIds.length > 0) {
      this.addPlayerWorkloads(addedAthleteIds)
    }
  }

  public deletePlayerWorkloads(ids: (string | number)[]): void {
    this.playerWorkloads = this.playerWorkloads.filter(rec => !ids.includes(rec.playerId))
  }

  public addPlayerWorkloads(ids: (string | number)[]): void {
    this.playerWorkloads = [
      ...this.playerWorkloads,
      ...this.generatePlayerWorkloads(ids),
    ]
  }

  public generatePlayerWorkloads(ids: (string | number)[]): WorkloadModel[] {
    const existingPlayers = this.players
    return ids.map(id => {
      const player = existingPlayers.find(rec => rec.id === id)
      const playerData = {
        player_id: id
      }
      if (player) {
        const playerJson = player.toJSON()
        delete playerJson.id
        ObjectHelper.assignIf(playerData, playerJson)
      } else {
        console.warn(`generatePlayerWorkloads: athlete with ID ${id} is not found`)
      }
      return new WorkloadModel(playerData)
    })
  }

  public removePlayersFromSessionElements(ids: (string | number)[]): void {
    this.sessionElements.forEach(sessionElement => {
      sessionElement.playerIds = sessionElement.playerIds.filter(id => !ids.includes(id))
    })
  }

  public assignGroup(group: GroupModel): void {
    if (!this.isGroupAssigned(group.id)) {
      this.groups = [
        ...this.groups,
        group
      ]
    }
  }
}

export default SessionModel
export { SESSION_STATUS_PUBLISHED, SESSION_STATUS_DRAFT }
