<template>
  <v-main>
    <v-container fill-height fluid>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md6 lg4 xl3>
          <div v-if="resetSuccess" class="text-center">
            <h1>
              Password reset request accepted
              <v-icon
                large
                color="green darken-3"
                style="margin-top: -10px;"
              >
                mdi-check
              </v-icon>
            </h1>
            <p>Check out your mailbox for a link to create a new password.</p>
          </div>
          <v-card v-else class="elevation-12 rounded-0">
            <v-toolbar
              color="primary"
              dark
            >
              <v-toolbar-title>Reset your password</v-toolbar-title>
            </v-toolbar>

            <v-card-text
              class="d-flex flex-column justify-center"
              style="height: 200px;"
            >
              <v-text-field
                ref="emailField"
                v-model="email"
                :rules="[rules.required]"
                class="flex-grow-0 flex-shrink-1"
                label="Enter your email"
                name="email"
                prepend-icon="mdi-email"
                type="text"
                @keyup.enter="passwordResetClick"
              />
            </v-card-text>

            <v-card-actions>
              <v-btn
                text
                :to="{name: 'auth.login'}"
              >
                Back to login
              </v-btn>
              <v-spacer />
              <v-btn
                color="primary"
                min-width="100px"
                :loading="submittingData"
                @click="passwordResetClick"
              >
                Reset password
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import Vue from 'vue'
import { Action } from 'vuex-class'
import { Component } from 'vue-property-decorator'

@Component
export default class ForgotPassword extends Vue {
  data() {
    return {
      email: '',
      submittingData: false,
      resetSuccess: false,
      rules: {
        required: value => !!value || 'Required',
      },
    }
  }

  @Action('Auth/PASSWORD_RESET') passwordReset

  resetForm() {
    this.email = ''

    this.$refs.emailField.resetValidation()
  }

  async passwordResetClick() {
    const isEmailValid = this.$refs.emailField.validate()

    if (!isEmailValid || this.submittingData) {
      return
    }

    this.submittingData = true

    try {
      const success = await this.passwordReset(this.email)

      if (success) {
        this.resetSuccess = true
      }
    } catch (e) {
      throw e
    } finally {
      this.resetForm()
      this.submittingData = false
    }
  }
}
</script>
