import Vue from 'vue'
import VueRouter, {Route} from 'vue-router'
import store from '@/store/store'
import {path} from 'rambda'
import LoginUser from '@/views/pages/auth/LoginUser.vue'
import ResetPassword from '@/views/pages/auth/ResetPassword.vue'
import ForgotPassword from '@/views/pages/auth/ForgotPassword.vue'
import CalendarDashboard from '@/views/pages/calendar/CalendarDashboard.vue'
import SecuritySetup from '@/views/pages/user/SecuritySetup.vue'
import PasswordChange from '@/views/pages/user/PasswordChange.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'
import MainLayout from '@/layouts/MainLayout.vue'
import SchedulerLayout from '@/layouts/SchedulerLayout.vue'
import nonAuthorized from '@/middlewares/nonAuthorized'
import authorized from '@/middlewares/authorized'

Vue.use(VueRouter)

const routes = [
    {
        name: 'auth.login',
        path: '/auth/login',
        component: LoginUser,
        meta: {
            layout: AuthLayout,
            middleware: [nonAuthorized]
        }
    },

    {
        name: 'auth.reset-password',
        path: '/auth/reset-password/:token',
        component: ResetPassword,
        meta: {
            layout: AuthLayout,
            middleware: [nonAuthorized]
        }
    },

    {
        path: '/auth/reset-password/',
        redirect: '/auth/reset-password/undefined'
    },

    {
        name: 'auth.forgot-password',
        path: '/auth/forgot-password',
        component: ForgotPassword,
        meta: {
            layout: AuthLayout,
            middleware: [nonAuthorized]
        }
    },

    {
        name: 'user.security',
        path: '/user/security',
        component: SecuritySetup,
        meta: {
            layout: MainLayout,
            middleware: [authorized]
        }
    },
    {
        name: 'user.password-change',
        path: '/user/password-change',
        component: PasswordChange,
        meta: {
            layout: MainLayout,
            middleware: [authorized]
        }
    },
    {
        path: '/',
        redirect: '/calendar'
    },
    {
        path: '/calendar',
        name: 'calendar',
        component: CalendarDashboard,
        meta: {
            layout: SchedulerLayout,
            middleware: [authorized]
        }
    },
]

const index = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

index.beforeEach((to: Route, from: Route, next: any) => {
    if (!(path(['meta', 'middleware'], to))) {
        return next()
    }
    const middleware = to?.meta?.middleware
    const context = {
        to,
        from,
        next,
        store,
    }
    return middleware[0]({
        ...context,
    })
})

export default index
