import { DomHelper } from '@bryntum/schedulerpro'
import LocalStorageHelper from '@/components/bryntum/helper/LocalStorageHelper'

const THEME_CLASS_PREFIX = 'b54-theme'

const DEFAULT_THEME = 'material'

export const AVAILABLE_THEMES = [
  {
    id: 'classic',
    __text: 'Classic',
    __icon: 'mdi mdi-palette-outline',
  },
  {
    id: 'classic-dark',
    __text: 'Dark',
    __icon: 'mdi mdi-weather-night',
  },
  {
    id: 'classic-light',
    __text: 'Light',
    __icon: 'mdi mdi-weather-sunny',
  },
  {
    id: 'material',
    __text: 'Material',
    __icon: 'mdi mdi-material-ui',
  },
  {
    id: 'stockholm',
    __text: 'Stockholm',
    __icon: 'mdi mdi-crown',
  },
]

export const getSelectedTheme = () => {
  const themeId = LocalStorageHelper.getLocalStorageItem(THEME_CLASS_PREFIX)
  return isValid(themeId) ? themeId : DEFAULT_THEME
}

export const setSelectedTheme = (newThemeId: string, oldThemeId: string): string => {
  const themeId = isValid(newThemeId) ? newThemeId : DEFAULT_THEME

  // If themeInfo is missing, it means DOM doesn't have the theme identifier
  if (!DomHelper.themeInfo) {
    console.warn('DOM is not ready to apply Bryntum theme')
    return themeId
  }

  DomHelper.setTheme(themeId).then(() => {
    const { body} = document

    LocalStorageHelper.setLocalStorageItem(THEME_CLASS_PREFIX, themeId)

    if (isValid(oldThemeId)) {
      body.classList.remove(`${THEME_CLASS_PREFIX}-${oldThemeId}`)
    }

    body.classList.add(`${THEME_CLASS_PREFIX}`, `${THEME_CLASS_PREFIX}-${themeId}`)
  })

  return themeId
}

const isValid = (themeId: string | null): boolean => {
  return Boolean(themeId && AVAILABLE_THEMES.find((item) => item.id === themeId))
}
