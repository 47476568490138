<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="8" lg="6" xl="4">
        <h1>Two-Factor Authentication</h1>
        <p class="ma-0">
          Two-Factor Authentication (2FA) adds an extra layer of security to your account. When enabled,
          you'll need to enter a 6-digit code from your Authenticator App, in addition to your password, to log in.
        </p>
      </v-col>
    </v-row>
    <v-row v-if="is2faEnabled" justify="center">
      <v-col cols="12" sm="8" lg="6" xl="4">
        <v-switch v-model="twoFactorSwitch">
          <template #label>
            Your account is secured by 2FA
            <v-icon color="green darken-3">mdi-shield-check</v-icon>
          </template>
        </v-switch>
        <v-text-field
          v-if="!twoFactorSwitch"
          v-model="currentPassword"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.lengthMin8]"
          :type="showPassword ? 'text' : 'password'"
          label="Enter account password to confirm"
          prepend-icon="mdi-lock"
          @click:append="showPassword = !showPassword"
          @keyup.enter="disableTwoFactorClick"
        />
        <v-btn
          v-if="!twoFactorSwitch"
          class="mt-2 float-right"
          primary
          color="primary"
          :disabled="isPasswordEmpty || isLoading"
          @click="disableTwoFactorClick"
        >
          Disable 2FA
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-else justify="center">
      <v-col cols="12" sm="8" lg="6" xl="4">
        <v-switch v-model="twoFactorSwitch">
          <template #label>
            2FA is disabled for your account
            <v-icon color="grey darken-3">mdi-shield-off</v-icon>
          </template>
        </v-switch>
        <v-container v-if="twoFactorSwitch" class="pa-0 mt-4">
          <v-row>
            <v-col>
              <h2><v-chip>1</v-chip> Download Authenticator App</h2>
              <p>
                Download and install the Google Authenticator app on your smartphone.
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h2><v-chip>2</v-chip> Scan QR code</h2>
              <p>
                Open the Google Authenticator app and create a new record. Then either scan the QR code from the screen
                or enter the activation key manually.
              </p>
              <div class="d-flex flex-column align-center">
                <v-card v-if="qrCode.image" outlined>
                  <v-card-title>
                    <v-img
                      :src="qrCode.image"
                      width="250"
                    />
                  </v-card-title>
                  <v-card-text class="text-center">
                    <span class="blue--text subtitle-1">{{ qrCode.secret }}</span>
                  </v-card-text>
                </v-card>
                <v-card v-else outlined>
                  <v-card-title>
                    <v-skeleton-loader
                      width="250"
                      height="250"
                      type="image"
                      class="qr-code-skeleton"
                    ></v-skeleton-loader>
                  </v-card-title>
                  <v-card-text class="text-center">
                    <v-skeleton-loader
                      width="160"
                      type="text"
                      class="activation-key-skeleton"
                    >
                    </v-skeleton-loader>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h2><v-chip>3</v-chip> Enter verification code</h2>
              <p>
                Copy a 6-digit code from the Google Authenticator app and paste it below.
              </p>
              <v-otp-input
                ref="otpInput"
                v-model="sixDigitCode"
                :disabled="!qrCode.image"
                length="6"
                type="number"
                class="ma-auto"
                style="max-width: 300px"
                @finish="onCopyPasteCode"
              ></v-otp-input>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Vue from 'vue'
import { Action, Getter, Mutation } from 'vuex-class'
import { Component, Watch } from 'vue-property-decorator'

@Component
export default class UserSecurity extends Vue {
  data() {
    return {
      rules: {
        required: value => !!value || 'Required',
        lengthMin8: value => value?.length >= 8 || 'Minimum 8 characters',
      },
      showPassword: false,
      currentPassword: '',
      twoFactorSwitch: false,
      sixDigitCode: '',
    }
  }

  get isPasswordEmpty() {
    return this.currentPassword === ''
  }

  @Watch('twoFactorSwitch')
  onTwoFactorSwitchChange(enabled) {
    if (!this.is2faEnabled) {
      if (enabled) {
        this.getQrCode()
      } else {
        // Reset QR code and 6-digit code
        this.setQRCode(null)
        this.resetCode()
      }
    }
  }

  @Watch('sixDigitCode')
  onSixDigitCodeChange(code) {
    if (code?.length === 6) {
      this.submitCode()
    }
  }

  @Mutation('Auth/SET_FA_ENABLED') setFaEnabled
  @Mutation('LoginSecurity/SET_QR_CODE') setQRCode
  @Mutation('Index/SET_LOADING') setLoading

  @Action('Auth/ME') fetchUser
  @Action('LoginSecurity/GENERATE_QR_CODE') generateQrCode
  @Action('LoginSecurity/ENABLE_TWO_FACTOR') enableTwoFactor
  @Action('LoginSecurity/DISABLE_TWO_FACTOR') disableTwoFactor

  @Getter('LoginSecurity/qrCode') qrCode
  @Getter('Auth/user') user
  @Getter('Auth/is2faEnabled') is2faEnabled
  @Getter('Index/loading') isLoading

  async mounted() {
    this.setLoading(true)

    // Even though the user info might be there, it is better to refresh it since another user who shares
    // credentials might have changed the 2FA value.
    await this.fetchUser()

    this.twoFactorSwitch = this.is2faEnabled

    this.setLoading(false)
  }

  async disableTwoFactorClick() {
    if (this.is2faEnabled && !this.isPasswordEmpty) {
      this.setLoading(true)

      try {
        const success = await this.disableTwoFactor(this.currentPassword)

        if (success) {
          this.setFaEnabled(false)
        }
      } catch (e) {
        throw e
      } finally {
        this.currentPassword = ''
        this.setLoading(false)
      }
    }
  }

  onCopyPasteCode(value) {
    this.sixDigitCode = value
  }

  resetCode() {
    this.sixDigitCode = ''
    // Workaround for a bug in OTP component to update the value in every OPT box
    if (this.$refs.otpInput) {
      this.$refs.otpInput.otp = []
    }
  }

  async submitCode() {
    if (this.sixDigitCode.length !== 6) {
      return
    }

    this.setLoading(true)

    try {
      const success = await this.enableTwoFactor(this.sixDigitCode)

      if (success) {
        this.setFaEnabled(true)
      }
    } catch (e) {
      throw e
    } finally {
      this.resetCode()
      this.setLoading(false)
    }
  }

  async getQrCode() {
    try {
      await this.generateQrCode()
    } catch (e) {
      throw e
    }
  }
}
</script>

<style scoped>

</style>
