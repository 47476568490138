import { Store, StoreConfig } from '@bryntum/schedulerpro'
import SquadModel from '@/components/bryntum/models/SquadModel'

class SquadStore extends Store {
  static $name = 'SquadStore'

  static get defaultConfig(): Partial<StoreConfig> {
    return {
      modelClass: SquadModel,
      sorters: [
        { field: 'title', ascending: true }
      ]
    }
  }
}

export default SquadStore
