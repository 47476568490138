import { ButtonConfig } from '@bryntum/schedulerpro'
import { ZoomLevelsConfig } from '@/components/bryntum/configs/ZoomLevelsConfig'
import { AVAILABLE_THEMES } from '@/components/bryntum/configs/ThemeSwitcherConfig'
import { SESSION_STATUS_DRAFT, SESSION_STATUS_PUBLISHED } from '@/components/bryntum/models/SessionModel'

const STATUS_FILTER_ITEMS = [
  {
    id: 'all',
    text: 'All statuses',
    tooltip: 'Show both published and unpublished sessions',
  },
  {
    id: SESSION_STATUS_PUBLISHED,
    text: 'Published',
    tooltip: 'Show only published sessions',
  },
  {
    id: SESSION_STATUS_DRAFT,
    text: 'Unpublished',
    tooltip: 'Show only unpublished sessions',
  },
].map(item => ({
  ...item,
  __tooltip: `${item.tooltip}`,
  checked: false,
  toggleGroup: 'statusFilterGroup',
  closeParent: true,
}))

const StatusFilterConfig: Partial<ButtonConfig> = {
  text: STATUS_FILTER_ITEMS[0].text,
  tooltip: STATUS_FILTER_ITEMS[0].tooltip,
  menu: STATUS_FILTER_ITEMS,
}

export const FIT_MENU_ITEM_NONE = 'none'
export const FIT_MENU_ITEM_FILL = 'fill'
export const FIT_MENU_ITEM_FIT = 'fit'

const FIT_MENU_ITEMS = [
  {
    id: FIT_MENU_ITEM_NONE,
    __text: 'No fit',
    __icon: 'mdi mdi-drag-vertical-variant',
    tooltip: 'Column width is equal to configured value. See the Sizing tool.',
  },
  {
    id: FIT_MENU_ITEM_FILL,
    __text: 'Fill width',
    __icon: 'mdi mdi-arrow-split-vertical',
    tooltip: 'Stretches columns to fill all available space. Has no effect if total column width greater than available space.',
  },
  {
    id: FIT_MENU_ITEM_FIT,
    __text: 'Fit width',
    __icon: 'mdi mdi-arrow-expand-horizontal',
    tooltip: 'Makes columns to take all available space. Ignores column width.',
  },
].map(item => ({
  ...item,
  text: `<i class="${item.__icon}"></i>${item.__text}`,
  __tooltip: `${item.__text} &mdash; ${item.tooltip}`,
  checked: false,
  toggleGroup: 'fitGroup',
  closeParent: true,
}))

const FitButtonConfig: Partial<ButtonConfig> = {
  text: 'Fit',
  icon: 'b-fa-arrows-alt-h',
  tooltip: 'Adjust how columns fit the browser width',
  menu: FIT_MENU_ITEMS,
}

export const COLUMN_WIDTH_MIN = 140
export const COLUMN_WIDTH_MAX = 500
export const TICK_HEIGHT_MIN = 20
export const TICK_HEIGHT_MAX = 500

const SizingButtonConfig: Partial<ButtonConfig> = {
  text: 'Sizing',
  icon: 'mdi mdi-tune-variant',
  tooltip: 'Adjust how much space a column or the timeline takes',
  menu: {
    columnWidth: {
      type: 'slider',
      text: 'Column width',
      min: COLUMN_WIDTH_MIN,
      max: COLUMN_WIDTH_MAX,
      value: COLUMN_WIDTH_MIN,
    },
    tickHeight: {
      type: 'slider',
      text: 'Tick height',
      style: 'margin-top: .6em',
      min: TICK_HEIGHT_MIN,
      max: TICK_HEIGHT_MAX,
      value: TICK_HEIGHT_MIN,
    },
  },
}

const ThemeButtonConfig: Partial<ButtonConfig> = {
  text: 'Theme',
  icon: 'mdi mdi-palette-outline',
  tooltip: 'Adjust color scheme of the calendar',
  menu: AVAILABLE_THEMES.map(item => ({
    ...item,
    text: `<i class="${item.__icon}"></i>${item.__text}`,
    checked: false,
    toggleGroup: 'themeGroup',
    closeParent: true,
  }))
}

const ViewPresetButtonConfig: Partial<ButtonConfig> = {
  text: 'View',
  tooltip: 'Select view',
  menu: ZoomLevelsConfig.map(item => ({
    ...item,
    text: item.name,
    checked: false,
    toggleGroup: 'viewPresetGroup',
    closeParent: true,
  }))
}

export {
  StatusFilterConfig,
  FitButtonConfig,
  SizingButtonConfig,
  ThemeButtonConfig,
  ViewPresetButtonConfig,
}
