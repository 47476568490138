export interface State {
    layout: string;
    loading: boolean;
    list: string[];
    snackbar: {
        message: string;
        visible: boolean;
        type: string
    };
}

const Index = {
    namespaced: true,
    root: true,
    state: {
        layout: 'main-layout',
        loading: true,
        snackbar: {
            message: '',
            visible: false,
            type: 'error',
        },
    },
    mutations: {
        SET_SNACKBAR_SUCCESS(state: any, payload: any) {
            state.snackbar.message = payload
            state.snackbar.visible = true
            state.snackbar.type = 'success'
        },
        SET_SNACKBAR_INFO(state: any, payload: any) {
            state.snackbar.message = payload
            state.snackbar.visible = true
            state.snackbar.type = 'info'
        },
        SET_SNACKBAR_ERROR(state: any, payload: any) {
            state.snackbar.message = payload
            state.snackbar.visible = true
            state.snackbar.type = 'error'
        },
        SET_LOADING(state: any, payload: any) {
            state.loading = payload
        },
        HIDE_SNACK(state: any) {
            state.snackbar.visible = false
        },
    },

    getters: {
        layout(state: State) {
            return state.layout
        },
        loading(state: State) {
            return state.loading
        },
        snackbar(state: State) {
            return state.snackbar
        },
    },
}

export default Index
