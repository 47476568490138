import { DataFieldConfig, Model } from '@bryntum/schedulerpro'
import PlayerModel from '@/components/bryntum/models/PlayerModel'

// Implicitly adds new fields to an existing interface
interface GroupModel {
  id: string | number
  title: string
  players: PlayerModel[]
  teamId: number
}

// Explicitly extends Bryntum types and adds missing types
// TODO: remove once the issue is fixed: https://github.com/bryntum/support/issues/5017
interface DataFieldConfigExtension extends DataFieldConfig {
  type: string

  // TODO: remove once the issue is fixed: https://www.bryntum.com/forum/viewtopic.php?f=51&t=22096
  convert(value: unknown): unknown

  serialize(value: unknown, record: Model): unknown
}

/**
 * EventTypeModel is used to describe group in player-groups[]
 */
class GroupModel extends Model {
  static $name = 'GroupModel'

  static get fields(): Partial<DataFieldConfigExtension>[] {
    return [
      { name: 'title', type: 'string' },
      {
        name: 'players',
        type: 'array',
        defaultValue: [],
        convert(players: any[]) {
          return players.map((player) => {
            return player instanceof PlayerModel ? player : new PlayerModel(player)
          })
        }
      },
      { name: 'teamId', type: 'number', dataSource: 'team_id' },
    ]
  }

  get isGroupModel(): boolean {
    return true
  }

  get playerCount(): number {
    return this.players.length
  }

  get playerNames(): string[] {
    return this.players.map((player: PlayerModel) => player.fullNameAbbr)
  }
}

export default GroupModel
