import { SchedulerConfig, BryntumSchedulerConfigExtension } from './SchedulerConfig'

const PAGE_ORIENTATION_LANDSCAPE = 'landscape'
const PAGE_ORIENTATION_PORTRAIT = 'portrait'

const SchedulerExportConfig: Partial<BryntumSchedulerConfigExtension> = {
  ...SchedulerConfig,
  subGridConfigs: {
    locked: {
      width: 50,
    },
  },
  verticalTimeAxisColumn: {
    filterable: false,
  },
  resourceColumns: {
    ...SchedulerConfig.resourceColumns,
    fitWidth: true,
  },
  forceFit: true,
}

export {
  SchedulerExportConfig,
  PAGE_ORIENTATION_LANDSCAPE,
  PAGE_ORIENTATION_PORTRAIT,
}
