<template>
  <v-main>
    <v-container fill-height fluid>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md6 lg4 xl3>
          <v-card class="elevation-12 rounded-0">
            <v-form @submit.prevent="loginClick">
              <v-toolbar
                color="primary"
                dark
              >
                <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
              </v-toolbar>

              <v-card-text
                class="d-flex flex-column justify-center"
                style="height: 200px;"
              >
                <v-otp-input
                  v-if="showSixDigitCodeForm"
                  ref="otpInput"
                  v-model="loginForm.two_factor_code"
                  class="flex-grow-0 flex-shrink-1 ma-auto"
                  length="6"
                  type="number"
                  style="max-width: 300px"
                  @finish="onCopyPasteCode"
                />

                <template v-else>
                  <v-text-field
                    v-model="loginForm.email"
                    :rules="[rules.required]"
                    class="flex-grow-0 flex-shrink-1"
                    label="Login"
                    name="login"
                    persistent-placeholder
                    prepend-icon="mdi-account-circle"
                    type="text"
                  />
                  <v-text-field
                    id="password"
                    v-model="loginForm.password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.lengthMin8]"
                    class="flex-grow-0 flex-shrink-1"
                    label="Password"
                    name="password"
                    persistent-placeholder
                    prepend-icon="mdi-lock"
                    :type="showPassword ? 'text' : 'password'"
                    autofocus
                    @click:append="showPassword = !showPassword"
                  />
                </template>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  text
                  :to="{ name: 'auth.forgot-password' }"
                >
                  Forgot password?
                </v-btn>
                <v-spacer />
                <v-btn
                  color="primary"
                  type="submit"
                  min-width="100px"
                  :loading="submittingData"
                >
                  {{ submitButtonText }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import Vue from 'vue'
import { Action } from 'vuex-class'
import { Component } from 'vue-property-decorator'

@Component
export default class LoginUser extends Vue {
  data() {
    return {
      rules: {
        required: value => !!value || 'Required',
        lengthMin8: value => value?.length >= 8 || 'Minimum 8 characters',
      },
      showSixDigitCodeForm: false,
      submittingData: false,
      showPassword: false,
      loginForm: {
        two_factor_code: null,
        email: '',
        password: '',
      }
    }
  }

  get formTitle() {
    return this.showSixDigitCodeForm ? 'Enter 6-digit code' : 'Login'
  }

  get submitButtonText() {
    return this.showSixDigitCodeForm ? 'Confirm' : 'Login'
  }

  @Action('Auth/LOGIN') login

  onCopyPasteCode(value) {
    this.loginForm.two_factor_code = value
    this.loginClick()
  }

  async loginClick() {
    if (this.showSixDigitCodeForm && this.loginForm.two_factor_code?.length !== 6) {
      return
    }

    this.submittingData = true

    try {
      const is2faCodeRequired = await this.login(this.loginForm)

      if (is2faCodeRequired) {
        this.showSixDigitCodeForm = true

        this.$nextTick(() => {
          this.$refs.otpInput.focus()
        })
      }
    } catch (e) {
      throw e
    } finally {
      this.submittingData = false
    }
  }
}
</script>
