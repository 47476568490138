import VueAxios from '@/api/VueAxios'
import SnackbarError from '@/components/snackbar/SnackbarError'
import SnackbarMessage from '@/components/snackbar/SnackbarMessage'

declare global {
  interface Window {
    $cookies: any;
  }
}

const profile = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async CHANGE_PASSWORD({ commit, dispatch }: any, params: any) {
      try {
        const response = await VueAxios.post('/user-profile/change-password', params)

        const { success, message } = response.data
        SnackbarMessage({ commit, dispatch }, message)

        // Temp workaround for BE typo
        return success || response.data.sucess
      } catch (err) {
        SnackbarError({ commit, dispatch }, err)
        throw err
      }
    },
  },
  getters: {},

}

export default profile
