<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="8" lg="6" xl="4">
        <h1>Change your password</h1>
        <v-form
          ref="passwordForm"
          @keyup.enter="passwordChangeClick"
        >
          <v-text-field
            v-model="passwordForm.current_password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.lengthMin8]"
            :type="showPassword ? 'text' : 'password'"
            label="Enter current password"
            prepend-icon="mdi-lock"
            @click:append="showPassword = !showPassword"
          />
          <v-text-field
            v-model="passwordForm.new_password"
            :rules="[rules.required, rules.lengthMin8]"
            :type="showPassword ? 'text' : 'password'"
            label="Enter new password"
            prepend-icon="mdi-lock"
          />
          <v-text-field
            v-model="passwordForm.new_password_confirmation"
            :rules="[rules.required, rules.lengthMin8, newPasswordsMatchCheck]"
            :type="showPassword ? 'text' : 'password'"
            label="Repeat new password"
            prepend-icon="mdi-lock"
            @keyup.enter="passwordChangeClick"
          />
        </v-form>
        <v-btn
          class="mt-2 float-right"
          primary
          color="primary"
          @click="passwordChangeClick"
        >
          Change password
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'

@Component
export default class PasswordChange extends Vue {
  data() {
    return {
      rules: {
        required: value => !!value || 'Required',
        lengthMin8: value => value?.length >= 8 || 'Minimum 8 characters',
      },
      showPassword: false,
      passwordForm: {
        current_password: '',
        new_password: '',
        new_password_confirmation: '',
      }
    }
  }

  @Mutation('Index/SET_LOADING') setLoading

  @Action('Profile/CHANGE_PASSWORD') changePassword
  @Action('Auth/LOGOUT') logout

  @Getter('Index/loading') isLoading

  async mounted() {
    this.setLoading(false)
  }

  newPasswordsMatchCheck(newPasswordConfirmation) {
    return this.passwordForm.new_password === newPasswordConfirmation || 'Passwords do not match'
  }

  resetForm() {
    this.passwordForm = {
      current_password: '',
      new_password: '',
      new_password_confirmation: '',
    }

    this.$refs.passwordForm.resetValidation()
  }

  async passwordChangeClick() {
    const isFormValid = this.$refs.passwordForm.validate()

    if (!isFormValid) {
      return
    }

    this.setLoading(true)

    let success = false

    try {
      success = await this.changePassword(this.passwordForm)

      if (success) {
        // Redirects to login page
        this.logout()
      }
    } catch (e) {
      throw e
    } finally {
      if (!success) {
        this.resetForm()
        this.setLoading(false)
      }
    }
  }
}
</script>
