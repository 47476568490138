import VueAxios from '@/api/VueAxios'
import SnackbarError from '@/components/snackbar/SnackbarError'
import SnackbarMessage from '@/components/snackbar/SnackbarMessage'
import router from '@/router'

declare global {
  interface Window {
    $cookies: any;
  }
}

const Auth = {
  namespaced: true,
  state: {
    user: window.$cookies.get('user') || {},
    token: window.$cookies.get('access_token') || ''
  },
  mutations: {
    SET_AUTH(state: any, payload: any) {
      state.user = payload.user || {}
      state.token = payload.token || ''
      window.$cookies.set('user', JSON.stringify(state.user))
      window.$cookies.set('access_token', state.token)
    },
    SET_FA_ENABLED(state: any, enabled: boolean) {
      state.user.two_fa_enabled = enabled
      window.$cookies.set('user', JSON.stringify(state.user))
    }
  },
  actions: {
    async LOGOUT({ commit, dispatch }: any, params: any) {
      try {
        const response = await VueAxios.post('/auth/logout')
        const { success } = response.data
        if (success) {
          window.$cookies.remove('access_token')
          window.$cookies.remove('user')
          await commit('SET_AUTH', { token: null, user: null })
          await router.push({ name: 'auth.login' })
        }
      } catch (err) {
        SnackbarError({ commit, dispatch }, err)
        throw err
      }
    },
    async ME({ commit, dispatch, state }: any, params: any) {
      try {
        const response = await VueAxios.get('/auth/me')
        const user = response.data

        commit('SET_AUTH', { token: state.token, user })

        return user
      } catch (err) {
        SnackbarError({ commit, dispatch }, err)
        throw err
      }
    },
    async PASSWORD_RESET({ commit, dispatch }: any, email: any) {
      try {
        const response = await VueAxios.post('/auth/password-reset', {
          email
        })

        const { success, message } = response.data
        SnackbarMessage({ commit, dispatch }, message)

        return success
      } catch (err) {
        SnackbarError({ commit, dispatch }, err)
        throw err
      }
    },
    async SET_NEW_PASSWORD({ commit, dispatch }: any, params: any) {
      try {
        const response = await VueAxios.post('/auth/set-new-password', params)

        const { message } = response.data
        SnackbarMessage({ commit, dispatch }, message)

        await router.push({ name: 'auth.login' })
      } catch (err) {
        SnackbarError({ commit, dispatch }, err)
        throw err
      }
    },
    async LOGIN({ commit, dispatch }: any, params: any) {
      VueAxios.defaults.headers.common.Authorization = null
      try {
        const response = await VueAxios.post('/auth/login', params)

        // It returns 206 when 2FA code is required
        if (response.status === 206) {
          SnackbarMessage({ commit, dispatch }, 'Please enter a 6-digit code from your authenticator app')
          return true
        }

        const { access_token, user } = response.data

        await commit('SET_AUTH', { token: access_token, user })

        VueAxios.defaults.headers.common.Authorization = 'Bearer ' + access_token
        router.push({ name: 'calendar' })
      } catch (err) {
        SnackbarError({ commit, dispatch }, err)
        throw err
      }
    }
  },
  getters: {
    token(state: any) {
      return state.token
    },
    user(state: any) {
      return state.user
    },
    isLoggedIn(state: any) {
      return !!state.token
    },
    is2faEnabled(state: any) {
      return !!state.user.two_fa_enabled
    }
  },

}

export default Auth
