<template>
  <v-main>
    <v-container fill-height fluid>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md6 lg4 xl3>
          <div v-if="!passwordForm.token" class="text-center">
            <h1>No token provided</h1>
            <p>Check out your mailbox for a correct link.</p>
          </div>
          <v-card v-else class="elevation-12 rounded-0">
            <v-toolbar
              color="primary"
              dark
            >
              <v-toolbar-title>Set a new password</v-toolbar-title>
            </v-toolbar>

            <v-card-text
              class="d-flex flex-column justify-center"
              style="height: 200px;"
            >
              <v-text-field
                ref="passwordField"
                v-model="passwordForm.password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.lengthMin8]"
                :type="showPassword ? 'text' : 'password'"
                class="flex-grow-0 flex-shrink-1"
                label="Enter new password"
                name="password"
                prepend-icon="mdi-lock"
                @click:append="showPassword = !showPassword"
              />
              <v-text-field
                ref="passwordConfirmationField"
                v-model="passwordForm.password_confirmation"
                :rules="[rules.required, rules.lengthMin8, newPasswordsMatchCheck]"
                :type="showPassword ? 'text' : 'password'"
                class="flex-grow-0 flex-shrink-1"
                label="Repeat new password"
                name="password-confirmation"
                prepend-icon="mdi-lock"
                @keyup.enter="newPasswordConfirmClick"
              />
            </v-card-text>

            <v-card-actions>
              <v-btn
                text
                :to="{name: 'auth.login'}"
              >
                Back to login
              </v-btn>
              <v-spacer />
              <v-btn
                color="primary"
                min-width="100px"
                :loading="submittingData"
                @click="newPasswordConfirmClick"
              >
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import Vue from 'vue'
import { Action } from 'vuex-class'
import { Component } from 'vue-property-decorator'

@Component
export default class ForgotPassword extends Vue {
  data() {
    return {
      rules: {
        required: value => !!value || 'Required',
        lengthMin8: value => value?.length >= 8 || 'Minimum 8 characters',
      },
      showPassword: false,
      submittingData: false,
      passwordForm: {
        password: null,
        password_confirmation: null,
        token: null
      },
    }
  }

  @Action('Auth/SET_NEW_PASSWORD') setNewPassword

  mounted() {
    this.initTokenValue()
  }

  initTokenValue() {
    if (this.$route.params.token && this.$route.params.token !== 'undefined') {
      this.passwordForm.token = this.$route.params.token
    } else {
      this.passwordForm.token = null
    }
  }

  newPasswordsMatchCheck(newPasswordConfirmation) {
    return this.passwordForm.password === newPasswordConfirmation || 'Passwords do not match'
  }

  resetForm() {
    this.passwordForm.password = null
    this.passwordForm.password_confirmation = null

    this.$refs.passwordField.resetValidation()
    this.$refs.passwordConfirmationField.resetValidation()
  }

  async newPasswordConfirmClick() {
    const isFormValid = this.$refs.passwordField.validate() && this.$refs.passwordConfirmationField.validate()

    if (!isFormValid || this.submittingData) {
      return
    }

    this.submittingData = true

    try {
      // Redirects to login page
      await this.setNewPassword(this.passwordForm)
    } catch (e) {
      throw e
    } finally {
      this.resetForm()
      this.submittingData = false
    }
  }
}
</script>
