





































import { BryntumSchedulerPro } from '@bryntum/schedulerpro-vue'
import { DateHelper, TimeSpan } from '@bryntum/schedulerpro'
import OverrideHelper from '@/components/bryntum/helper/OverrideHelper'

export default {
  name: 'CalendarExportPage',

  components: { BryntumSchedulerPro },

  props: {
    squadName: { type: String, required: true },
    comment: { type: String, required: true },
    schedulerConfig: { type: Object, required: true },
    startDate: { type: Date, required: true },
    endDate: { type: Date, required: true },
    project: { type: Object, required: true },
    viewPreset: { type: [Object, String], required: true },
    workingTimeRange: { type: Array, required: true },
    schedulerStyle: { type: [Object, String], required: true },
    readOnly: { type: Boolean, default: false },
  },

  data: () => ({
    scheduler: null,
  }),

  computed: {
    formattedStartDate(): string {
      // "Friday, 10th January 2025"
      return this.startDate ? DateHelper.format(this.startDate, 'dddd, Do MMMM YYYY') : ''
    },
  },

  watch: {
    workingTimeRange(): void {
      this.filterOutNonWorkingTime()
    }
  },

  mounted(): void {
    const scheduler = this.scheduler = this.$refs.schedulerDay.instance

    OverrideHelper.overrideVerticalDateRange(scheduler)

    this.filterOutNonWorkingTime()
  },

  methods: {
    filterOutNonWorkingTime(): void {
      const [ start, end ] = this.workingTimeRange

      if (end - start === 24) {
        this.scheduler.timeAxis.clearFilters()
      } else {
        this.scheduler.timeAxis.filterBy((tick: TimeSpan) => {
          const tickStart = tick.startDate as Date

          return tickStart.getHours() >= start && tickStart.getHours() < end
        })
      }
    },
  },
}
