










import { BryntumGrid } from '@bryntum/schedulerpro-vue'
import { SessionTemplateGridConfig } from '@/components/bryntum/configs/SessionTemplateGridConfig'

export default {
  name: 'SessionTemplateGrid',

  components: {
    BryntumGrid,
  },

  props: {
    rawData: { type: Array, default: undefined },
    isAdmin: { type: Boolean, default: false },
  },

  emits: [
    'gridMounted',
    'gridUnmounted',
  ],

  data() {
    return {
      SessionTemplateGridConfig,
      gridInstance: null,
    }
  },

  computed: {
    createSessionTemplateUrl(): string {
      return `${process.env.VUE_APP_ADMIN_PORTAL_URL}/event-templates/create`
    },
  },

  watch: {
    isAdmin(value: boolean): void {
      this.updateGridRights(value)
    }
  },

  mounted(): void {
    this.gridInstance = this.$refs.sessionTemplateGrid.instance

    this.updateGridRights(this.isAdmin)
    this.gridInstance.on('addNewSessionTemplateClick', this.onAddNewSessionTemplateClick)

    this.$emit('gridMounted', this.gridInstance)
  },

  beforeDestroy(): void {
    this.gridInstance.un('addNewSessionTemplateClick')

    this.$emit('gridUnmounted', this.gridInstance)
  },

  methods: {
    updateGridRights(isAdmin: boolean): void {
      this.gridInstance.adminRights = isAdmin
    },
    onAddNewSessionTemplateClick(): void {
      if (this.isAdmin) {
        window.open(this.createSessionTemplateUrl)
      }
    },
  }
}
