<template>
  <v-snackbar
    v-model="snackbar.visible"
    :color="snackbar.type"
    :timeout="3000"
    multi-line
    top
    right
    text
  >
    {{ snackbar.message }}
    <template #action="{ attrs }">
      <v-btn
        primary
        plain
        icon
        fab
        v-bind="attrs"
        @click="closeSnack"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>

import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'

@Component
export default class Snackbar extends Vue {

  @Getter('Index/snackbar') snackbar

  @Mutation('Index/HIDE_SNACK') hideSnack

  closeSnack() {
    this.hideSnack(false)
  }
}
</script>
