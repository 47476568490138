































































import { mapGetters } from 'vuex'
import { BryntumCombo } from '@bryntum/schedulerpro-vue'
import SquadStore from '@/components/bryntum/stores/SquadStore'
import SquadModel from '@/components/bryntum/models/SquadModel'
import GroupGrid from '@/components/GroupGrid.vue'
import PlayerGrid from '@/components/PlayerGrid.vue'
import SessionTemplateGrid from '@/components/SessionTemplateGrid.vue'

export default {
  name: 'SidebarContainer',

  components: {
    BryntumCombo,
    GroupGrid,
    PlayerGrid,
    SessionTemplateGrid
  },

  props: {
    selectedSquad: { type: Object, default: undefined },
    squads: { type: Array, default: () => [] },
    groups: { type: Array, default: () => [] },
    players: { type: Array, default: () => [] },
    templates: { type: Array, default: () => [] },
  },

  emits: [
    'update:selectedSquad',
    'groupGridMounted',
    'groupGridUnmounted',
    'playerGridMounted',
    'playerGridUnmounted',
    'sessionTemplateGridMounted',
    'sessionTemplateGridUnmounted',
  ],

  data: (): {
    sidebarExpanded: boolean
    squadsStore?: SquadStore
    isAdmin: boolean
  } => ({
    sidebarExpanded: true,
    squadsStore: undefined,
    isAdmin: false,
  }),

  computed: {
    ...mapGetters({
      user: 'Auth/user',
    }),
  },

  watch: {
    squads(): void {
      this.setSquads()
    }
  },

  beforeMount(): void {
    const userRoles = this.user.roles || []
    this.isAdmin = userRoles.includes('admin')
  },

  async mounted(): Promise<void> {
    this.squadsStore = new SquadStore({ data: this.squads })
  },

  methods: {
    setSquads(): void {
      this.squadsStore.data = this.squads
    },

    onSquadSelection({ record, userAction }: { record: SquadModel, userAction: boolean }): void {
      if (userAction) {
        this.$emit('update:selectedSquad', record)
      }
    }
  }
}
