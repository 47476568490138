import {
  MenuItemConfig,
  ObjectHelper,
  ResourceModel,
  ScheduleMenu,
  Scheduler,
  SchedulerConfig as BryntumSchedulerConfig,
  SchedulerProFeaturesConfigType,
  StringHelper as SH,
} from '@bryntum/schedulerpro'
import SessionRendererHelper from '@/components/bryntum/helper/SessionRendererHelper'
import { TaskEditConfig } from '@/components/bryntum/configs/TaskEditConfig'
import { ZOOM_LEVEL_DAY, ZoomLevelsConfig } from '@/components/bryntum/configs/ZoomLevelsConfig'
import SessionStore from '@/components/bryntum/stores/SessionStore'
import SessionModel from '@/components/bryntum/models/SessionModel'

// TODO: use Bryntum type once the issue is fixed: https://github.com/bryntum/support/issues/5020
export interface BryntumSchedulerConfigExtension extends BryntumSchedulerConfig {
  eventCopyPasteFeature: SchedulerProFeaturesConfigType['eventCopyPaste']
  stripeFeature: SchedulerProFeaturesConfigType['stripe']
  timeRangesFeature: SchedulerProFeaturesConfigType['timeRanges']
  dependenciesFeature: SchedulerProFeaturesConfigType['dependencies']
  filterBarFeature: SchedulerProFeaturesConfigType['filterBar']
  eventMenuFeature: SchedulerProFeaturesConfigType['eventMenu']
  scheduleMenuFeature: SchedulerProFeaturesConfigType['scheduleMenu']
  eventFilterFeature: SchedulerProFeaturesConfigType['eventFilter']
  timeAxisHeaderMenuFeature: SchedulerProFeaturesConfigType['timeAxisHeaderMenu']
  headerMenuFeature: SchedulerProFeaturesConfigType['headerMenu']
  eventTooltipFeature: SchedulerProFeaturesConfigType['eventTooltip']
  scheduleTooltipFeature: SchedulerProFeaturesConfigType['scheduleTooltip']
  taskEditFeature: SchedulerProFeaturesConfigType['taskEdit']
}

interface EventMenuItemArgs {
  source: Scheduler
  eventRecord: SessionModel
}

interface ScheduleMenuItemArgs {
  source: Scheduler
  resourceRecord: ResourceModel
  date: Date
}

interface ProcessItemsArgs {
  items: {
    [key: string]: MenuItemConfig
  }
  date: Date
  resourceRecord: ResourceModel
  // private
  feature: ScheduleMenu
}

const SchedulerConfig: Partial<BryntumSchedulerConfigExtension> = {
  zoomOnMouseWheel: false,
  zoomOnTimeAxisDoubleClick: false,
  tickSize: 100, // tickHeight - to be determined later in beforeRouteEnter
  resourceColumns: {
    columnWidth: 100, // columnWidth - to be determined later in beforeRouteEnter
    headerRenderer: ({ resourceRecord }: { resourceRecord: any }) => {
      const style = resourceRecord.color ? `background-color: ${resourceRecord.color}` : ''

      return `
        <div class="header-box">
          <div
            class="location-name"
            data-btip="${resourceRecord.title}"
          >
            ${resourceRecord.title}
          </div>
          <div class="location-colored-bar" style="${style}" />
        </div>
      `
    },
  },
  subGridConfigs: {
    locked: {
      width: 150,
    },
  },
  verticalTimeAxisColumn: {
    filterable: {
      filterField: {
        type: 'text',
        cls: 'session-filter',
        placeholder: 'Filter by name...',
        onChange: ({ value, source: field }: any) => {
          const { owner: schedule } = field
          schedule.trigger('updateNameFilter', { value })
        },
      },
    },
  },
  // TODO: add controls to the toolbar to enable/disable/config working hours
  // workingTime: {
  //   fromHour: 6,
  //   toHour: 23
  // },
  barMargin: 0,
  eventStyle: 'colored',
  mode: 'vertical',
  eventLayout: 'pack',
  enableRecurringEvents: true,
  resourceImagePath: 'users/',
  createEventOnDblClick: {
    useEventModelDefaults: true,
  },
  columns: [
    { type: 'resourceInfo', field: 'title', text: 'Location', width: 165 },
  ],
  project: {
    autoLoad: true,
    eventStore: {
      ...SessionStore.defaultConfig,
    },
    autoSync: false,
    writeAllFields: true,
  },
  eventRenderer({ eventRecord: session }: { eventRecord: SessionModel }) {
    const snapshot = session.snapshotId
      ? session.eventStore.getById(session.snapshotId) as SessionModel
      : undefined

    let headerWrapper = ''
    let bodyWrapper = ''
    let footerWrapper = ''

    const headerContent = [
      SessionRendererHelper.renderPublishingInfo(session),
      SessionRendererHelper.renderSessionName(session, snapshot),
      SessionRendererHelper.renderDate(session, snapshot),
      SessionRendererHelper.renderTimeAndDuration(session, snapshot),
      SessionRendererHelper.renderLocation(session, snapshot),
      SessionRendererHelper.renderSessionTemplate(session),
    ].join('')

    if (headerContent.trim().length) {
      headerWrapper = `<div class="session-header">${headerContent}</div>`
    }

    const bodyContent = [
      SessionRendererHelper.renderSessionGroups(session, snapshot),
      SessionRendererHelper.renderSessionStaff(session, snapshot),
      SessionRendererHelper.renderSessionAthlete(session, snapshot),
    ].join('')

    if (bodyContent.trim().length) {
      bodyWrapper = `<div class="session-body">${bodyContent}</div>`
    }

    const footerContent = SessionRendererHelper.renderSessionNotes(session, snapshot)

    if (footerContent.trim().length) {
      footerWrapper = `<div class="session-footer">${footerContent}</div>`
    }

    return `
      <div class="session-box">
        ${headerWrapper}
        ${bodyWrapper}
        ${footerWrapper}
      </div>
    `
  },
  snap: true,
  presets: [...ZoomLevelsConfig],
  viewPreset: ZOOM_LEVEL_DAY,
  zoomKeepsOriginalTimespan: true,
  weekStartDay: 1, // TODO: remove when intro UK locale
  // region Features
  stripeFeature: true,
  timeRangesFeature: true,
  dependenciesFeature: false,
  filterBarFeature: true,
  // Disabled due to the bug: https://github.com/bryntum/support/issues/6021
  // Implemented a custom solution.
  eventCopyPasteFeature: false,
  eventMenuFeature: {
    // Process items before menu is shown
    processItems({ items, feature }: ProcessItemsArgs) {
      const { client } = feature

      if (items.copyEvent) {
        items.copyEvent.hidden = client.readOnly
      }
    },
    items: {
      copyEvent: {
        text: 'Copy Session',
        icon: 'b-fa-copy',
        weight: 190,
        onItem: ({ source: schedule, eventRecord: sessionRecord }: EventMenuItemArgs) => {
          schedule.trigger('copySessionPerTime', { sessionRecord })
        }
      },
      deleteEvent: {
        text: 'Delete Session',
      },
      unassignEvent: false,
    }
  },
  scheduleMenuFeature: {
    // Process items before menu is shown
    processItems({ items, feature }: ProcessItemsArgs) {
      const { client } = feature

      if (items.addEvent) {
        items.addEvent.hidden = client.readOnly
      }

      if (items.pasteEvent) {
        items.pasteEvent.hidden = client.readOnly
      }
    },
    items: {
      addEvent: {
        text: 'Create Session',
      },
      pasteEvent: {
        text: 'Paste Session',
        icon: 'b-fa-paste',
        weight: 195,
        onItem: ({ source: schedule, date: startDate, resourceRecord: locationRecord }: ScheduleMenuItemArgs) => {
          schedule.trigger('pasteSessionPerTime', { startDate, locationRecord })
        }
      },
    }
  },
  eventFilterFeature: false,
  timeAxisHeaderMenuFeature: false,
  headerMenuFeature: false,
  scheduleTooltipFeature: false,
  eventTooltipFeature: {
    allowOver: true,
    hideWhenEmpty: true,
    template: ({ eventRecord: session }: { eventRecord: SessionModel }) => {
      let html = ''

      if (session.sessionTemplate?.name) {
        html += `
          <div class="tooltip-content">
            <div class="tooltip-content-title">Session Template:</div>
            <div class="tooltip-content">
              <i>${SH.encodeHtml(session.sessionTemplate.name)}</i>
            </div>
          </div>
        `
      }

      if (session.groups.length > 0) {
        const groupsHtmlList = Object.values(session.groupsWithPlayerDiff).map(groupInfo => {
          let groupHtml = groupInfo.group.title

          if (groupInfo.removedPlayers.length > 0) {
            const removedPlayerNames = groupInfo.removedPlayers.map(player => player.fullNameAbbr)
            const removedPlayersHtml = `<span class="removed-players">${removedPlayerNames.join(', ')}</span>`

            groupHtml = `${groupHtml} (${removedPlayersHtml})`
          }

          return groupHtml
        })

        html += `
          <div class="tooltip-content">
            <div class="tooltip-content-title">Groups:</div>
            <div class="tooltip-content"><i>${groupsHtmlList.join(', ')}</i></div>
          </div>
        `
      }

      if (session.staffNames.length > 0) {
        html += `
          <div class="tooltip-content">
            <div class="tooltip-content-title">Staff:</div>
            <div class="tooltip-content"><i>${session.staffNames.join(', ')}</i></div>
          </div>
        `
      }

      if (session.athleteNames.length > 0) {
        html += `
          <div class="tooltip-content">
            <div class="tooltip-content-title">Athletes:</div>
            <div class="tooltip-content"><i>${session.athleteNames.join(', ')}</i></div>
          </div>
        `
      }

      if (session.publicNote && session.publicNote.length > 0) {
        html += `
          <div class="tooltip-content">
            <div class="tooltip-content-title">Notes:</div>
            <div class="tooltip-content"><i>${SH.encodeHtml(session.publicNote)}</i></div>
          </div>
        `
      }

      return html.length ? `<div>${html}</div>` : ''
    }
  },
  taskEditFeature: ObjectHelper.clone(TaskEditConfig),
  // endregion
}

export { SchedulerConfig }
