import { Scheduler, TimeSpan } from '@bryntum/schedulerpro'

// Explicitly extends Bryntum types and adds missing types
interface SchedulerExtension extends Scheduler {
  vertical: any
  virtualScrollHeight: number
}

/**
 * Override lib/Scheduler/view/orientation/VerticalRendering::dateRange
 * Fixes: https://forum.bryntum.com/viewtopic.php?t=13270
 *
 * To reproduce:
 * - scroll the day view all way down
 * - click export
 * - during the export the view is transformed and if fails to calculate end date
 * ERROR: Cannot read properties of null (reading 'getTime')
 * Solution: moved `bottomDate = scheduler.timeAxis.last.endDate` to the end
 *
 * @param scheduler
 */
const overrideVerticalDateRange = (scheduler: SchedulerExtension) => {
  Object.defineProperty(scheduler.vertical, 'dateRange', {
    get() {
      let bottomDate = scheduler.getDateFromCoordinate(Math.min(
        scheduler.scrollTop + scheduler.bodyHeight + scheduler.tickSize - 1,
        (scheduler.virtualScrollHeight || scheduler.scrollable.scrollHeight) - 1)
      )

      let topDate = scheduler.getDateFromCoordinate(Math.max(scheduler.scrollTop - scheduler.tickSize, 0))

      // Might end up above time axis when reconfiguring (since this happens as part of rendering)
      if (!topDate) {
        const first = scheduler.timeAxis.first as TimeSpan
        topDate = first.startDate as Date
        bottomDate = scheduler.getDateFromCoordinate(scheduler.bodyHeight + scheduler.tickSize - 1)
      }

      // Might end up below time axis (out of ticks)
      if (!bottomDate) {
        const last = scheduler.timeAxis.last as TimeSpan
        bottomDate = last.endDate as Date
      }

      return {
        topDate,
        bottomDate
      }
    },
  })
}

export default {
  overrideVerticalDateRange,
}
