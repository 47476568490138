import { DataFieldConfig, Model } from '@bryntum/schedulerpro'

// Implicitly adds new fields to an existing interface
interface SquadModel {
  id: string | number
  title: string
}

// Types of the raw fields
export interface SquadRawObject {
  id: string | number
  title: string
}

// Explicitly extends Bryntum types and adds missing types
// TODO: remove once the issue is fixed: https://github.com/bryntum/support/issues/5017
interface DataFieldConfigExtension extends DataFieldConfig {
  type: string
}

/**
 * SquadModel describes teams
 */
class SquadModel extends Model {
  static $name = 'SquadModel'

  static get fields(): Partial<DataFieldConfigExtension>[] {
    return [
      { name: 'title', type: 'string' },
    ]
  }
}

export default SquadModel
